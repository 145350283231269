import {
  ADD_USER_AUTH,
  UPDATE_USER_AUTH,
  TypesActionsUser
} from '../../actions/users/UsersTypesActions';
import { UserStateAuth } from '../../models/user/UserStateAuth';
import { UserAuthInitialValues } from 'src/app/feature/Auth/interfaces/interfaces';

const getDataUser = (): UserStateAuth => {
  const user = JSON.parse(localStorage.getItem('user'));
  return { user: user };
};

const initialState: UserStateAuth = localStorage.getItem('user')
  ? getDataUser()
  : {
      user: UserAuthInitialValues
    };

export default function userReducer(
  state = initialState,
  action: TypesActionsUser
): UserStateAuth {
  switch (action.type) {
    case ADD_USER_AUTH: {
      let user = action.payload;
      user.accessToken = action.accessToken;
      return {
        ...state,
        user
      };
    }
    case UPDATE_USER_AUTH: {
      const user = action.payload;
      return {
        ...state,
        user
      };
    }
    default:
      return state;
  }
}
