import * as Yup from 'yup';
import { UserAuth } from '../models/User';

export interface FormValues {
  email: string;
  password: string;
}
export const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  email: Yup.string()
    .email('Debe introdicir un email válido.')
    .required('El campo Usuario es requerido de tipo email.'),
  password: Yup.string()
    .min(8, 'Mínimo 8 caracteres')
    .max(16, 'Máximo 16 caracteres')
    .required('El campo Clave es requerido.')
});

export interface LoginPageProps {
  SignIn: (user: UserAuth, callback: Function) => void;
  initialValues?: FormValues;
  CheckUser: (form: object, callback: Function) => void;
}

export const UserAuthInitialValues: UserAuth = {
  _id: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  role: '',
  accessToken: ''
};
