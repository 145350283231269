import { Plan, ResponsePlansProps } from 'src/app/feature/Plans/models/Plan';
export const LIST_PLANS = 'LIST_PLANS';
export const ADD_PLAN = 'ADD_PLAN';
export const UPDATE_PLAN = 'UPDATE_PLAN';

interface ActionListPlans {
  type: typeof LIST_PLANS;
  payload: ResponsePlansProps;
}

interface ActionAddPlans {
  type: typeof ADD_PLAN;
  payload: Plan;
}

interface ActionUpdatePlan {
  type: typeof UPDATE_PLAN;
  payload: Plan;
}

export type TypesActionsPlans =
  | ActionListPlans
  | ActionAddPlans
  | ActionUpdatePlan;
