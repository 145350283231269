import {
  LIST_ACCOUNTS,
  ADD_ACCOUNT,
  UPDATE_ACCOUNT,
  TypesActionsAccounts
} from '../../actions/accounts/AccountsTypes';
import { AccountsState } from '../../models/accounts/AccountsState';

const initialState: AccountsState = {
  accounts: {
    docs: [],
    totalDocs: 0,
    limit: 0,
    totalPages: 0,
    page: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: 0,
    nextPage: 0
  },
  account: {
    _id: '',
    name: '',
    documentId: '',
    country: '',
    state: '',
    city: '',
    address: '',
    zip: '',
    phone: '',
    email: '',
    representative: '',
    description: '',
    plan: {
      _id: '',
      name: '',
      description: '',
      active: false,
      price: 0,
      setupFee: 0,
      rules: {},
      planType: ''
    },
    planPos: {
      _id: '',
      name: '',
      description: '',
      active: false,
      price: 0,
      setupFee: 0,
      rules: {},
      planType: ''
    },
    isEnable: false,
    lat: '',
    lon: '',
    status: '',
    image: '',
    banner: '',
    createdAt: '',
    deletedAt: '',
    __v: 0,
    feeServicesQuota: [],
    feeSetupTotalAmmount: 0,
    feeSetupObservation: ''
  }
};

export default function accountsReducer(
  state = initialState,
  action: TypesActionsAccounts
) {
  switch (action.type) {
    case LIST_ACCOUNTS: {
      const accounts = action.payload;
      return {
        ...state,
        accounts
      };
    }
    case ADD_ACCOUNT: {
      const account = action.payload;
      return {
        ...state,
        account
      };
    }
    case UPDATE_ACCOUNT: {
      const account = action.payload;
      const index = state.accounts.docs.findIndex((e) => e._id === account._id);
      const newArray = [...state.accounts.docs];
      newArray[index] = account;
      const { docs, ...rest } = state.accounts;
      const accounts = {
        docs: newArray,
        ...rest
      };
      return {
        ...state,
        accounts
      };
    }
    default:
      return state;
  }
}
