import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { PartialRouteObject } from 'react-router';
import SidebarLayout from 'src/app/shared/layouts/SidebarLayout';
import BaseLayout from 'src/app/shared/layouts/BaseLayout';
import SuspenseLoader from 'src/app/shared/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

// Dashboards

const Dashboard = Loader(
  lazy(() => import('src/app/feature/DashBoard/pages/Main'))
);

// Applications

const Transactions = Loader(
  lazy(() => import('src/app/shared/content/applications/Transactions'))
);

// Status

const Status404 = Loader(
  lazy(() => import('src/app/shared/content/pages/Status/Status404'))
);

// Food categories

const FoodCategories = Loader(
  lazy(() => import('src/app/feature/FoodCategories/Main/pages/Main'))
);
const FoodCategoriesCreate = Loader(
  lazy(() => import('src/app/feature/FoodCategories/Create/pages/Main'))
);
const FoodCategoriesEdit = Loader(
  lazy(() => import('src/app/feature/FoodCategories/Edit/pages/Main'))
);
// Food complements

const FoodComplements = Loader(
  lazy(() => import('src/app/feature/FoodComplements/Main/pages/Main'))
);
const FoodComplementsCreate = Loader(
  lazy(() => import('src/app/feature/FoodComplements/Create/pages/Main'))
);
const FoodComplementsEdit = Loader(
  lazy(() => import('src/app/feature/FoodComplements/Edit/pages/Main'))
);

// Tables

const Tables = Loader(
  lazy(() => import('src/app/feature/Tables/Main/pages/Main'))
);
const TablesCreate = Loader(
  lazy(() => import('src/app/feature/Tables/Create/pages/Main'))
);
const TablesEdit = Loader(
  lazy(() => import('src/app/feature/Tables/Edit/pages/Main'))
);

// Orders

const Orders = Loader(
  lazy(() => import('src/app/feature/Orders/Main/pages/Main'))
);
const OrdersDetail = Loader(
  lazy(() => import('src/app/feature/Orders/Detail/pages/Main'))
);
// const TablesCreate = Loader(
//   lazy(() => import('src/app/feature/Tables/Create/pages/Main'))
// );
// const TablesEdit = Loader(
//   lazy(() => import('src/app/feature/Tables/Edit/pages/Main'))
// );

//foods

const Foods = Loader(
  lazy(() => import('src/app/feature/Foods/Main/pages/Main'))
);
const FoodsCreate = Loader(
  lazy(() => import('src/app/feature/Foods/Create/pages/Main'))
);
const FoodsEdit = Loader(
  lazy(() => import('src/app/feature/Foods/Edit/pages/Main'))
);

// Users

const Users = Loader(
  lazy(() => import('src/app/feature/Users/Main/pages/Main'))
);
const UsersCreate = Loader(
  lazy(() => import('src/app/feature/Users/Create/pages/Main'))
);
const UsersEdit = Loader(
  lazy(() => import('src/app/feature/Users/Edit/pages/Main'))
);
const UsersDetail = Loader(
  lazy(() => import('src/app/feature/Users/Detail/pages/Main'))
);

// Accounts

const Accounts = Loader(
  lazy(() => import('src/app/feature/Accounts/Main/pages/Main'))
);

const AccountsDetail = Loader(
  lazy(() => import('src/app/feature/Accounts/Detail/pages/Main'))
);

const AccountsCreate = Loader(
  lazy(() => import('src/app/feature/Accounts/Create/pages/Main'))
);

const AccountsEdit = Loader(
  lazy(() => import('src/app/feature/Accounts/Edit/pages/Main'))
);

//Report

const Reports = Loader(
  lazy(() => import('src/app/feature/Report/Main/pages/Main'))
);
//Profile

const Profile = Loader(
  lazy(() => import('src/app/feature/ProfileEdit/pages/ProfileEditPage'))
);

// Plans

const Plans = Loader(
  lazy(() => import('src/app/feature/Plans/Main/pages/Main'))
);

const PlansCreate = Loader(
  lazy(() => import('src/app/feature/Plans/Create/pages/Main'))
);

const PlansEdit = Loader(
  lazy(() => import('src/app/feature/Plans/Edit/pages/Main'))
);

//Receipts

const Receipts = Loader(
  lazy(() => import('src/app/feature/Receipts/Main/pages/Main'))
);

const ReceiptsAccount = Loader(
  lazy(() => import('src/app/feature/Receipts/MainAccount/pages/Main'))
);

const routesProtected: PartialRouteObject[] = [
  {
    path: '*',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="/dashboard" replace />
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: 'foodCategories',
    element: <SidebarLayout />,
    children: [
      {
        path: '/',
        element: <FoodCategories />
      },
      {
        path: '/create',
        element: <FoodCategoriesCreate />
      },
      {
        path: '/:id/edit',
        element: <FoodCategoriesEdit />
      }
    ]
  },
  {
    path: 'users',
    element: <SidebarLayout />,
    children: [
      {
        path: '/',
        element: <Users />
      },
      {
        path: '/create',
        element: <UsersCreate />
      },
      {
        path: '/:id/edit',
        element: <UsersEdit />
      },
      {
        path: '/:id/detail',
        element: <UsersDetail />
      }
    ]
  },
  {
    path: 'dashboard',
    element: <SidebarLayout />,
    children: [
      {
        path: '/',
        element: <Dashboard />
      }
    ]
  },
  {
    path: 'accounts',
    element: <SidebarLayout />,
    children: [
      {
        path: '/',
        element: <Accounts />
      },
      {
        path: '/create',
        element: <AccountsCreate />
      },
      {
        path: '/:id/edit',
        element: <AccountsEdit />
      },
      {
        path: '/:id/detail',
        element: <AccountsDetail />
      },
      {
        path: '/:id/foodComplements',
        children: [
          {
            path: '/',
            element: <FoodComplements />
          },
          {
            path: '/create',
            element: <FoodComplementsCreate />
          },
          {
            path: '/:idFc/edit',
            element: <FoodComplementsEdit />
          }
        ]
      },
      {
        path: '/:id/tables',
        children: [
          {
            path: '/',
            element: <Tables />
          },
          {
            path: '/create',
            element: <TablesCreate />
          },
          {
            path: '/:idFc/edit',
            element: <TablesEdit />
          }
        ]
      },
      {
        path: '/:id/orders',
        children: [
          {
            path: '/',
            element: <Orders />
          },
          {
            path: '/:idOrder',
            element: <OrdersDetail />
          }
        ]
      },
      {
        path: '/:id/foods',
        children: [
          {
            path: '/',
            element: <Foods />
          },
          {
            path: '/create',
            element: <FoodsCreate />
          },
          {
            path: '/:idF/edit',
            element: <FoodsEdit />
          }
        ]
      },
      {
        path: '/:id/receipts',
        children: [
          {
            path: '/',
            element: <ReceiptsAccount />
          }
        ]
      }
    ]
  },
  {
    path: 'management',
    element: <SidebarLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="/management/transactions" replace />
      },
      {
        path: 'transactions',
        element: <Transactions />
      }
    ]
  },
  {
    path: 'reports',
    element: <SidebarLayout />,
    children: [
      {
        path: '/',
        element: <Reports />
      }
    ]
  },
  {
    path: 'profile',
    element: <SidebarLayout />,
    children: [
      {
        path: '/',
        element: <Profile />
      }
    ]
  },
  {
    path: 'plans',
    element: <SidebarLayout />,
    children: [
      {
        path: '/',
        element: <Plans />
      },
      {
        path: '/create',
        element: <PlansCreate />
      },
      {
        path: '/:id/edit',
        element: <PlansEdit />
      }
    ]
  },
  {
    path: 'receipts',
    element: <SidebarLayout />,
    children: [
      {
        path: '/',
        element: <Receipts />
      }
    ]
  }
];

export default routesProtected;
