import {
  TypesActionsOrders,
  LIST_ORDERS,
  ADD_ORDER,
  UPDATE_ORDER
} from '../../actions/orders/OrdersTypes';
import { OrdersState } from '../../models/orders/OrdersState';
import { UserInitialValues } from 'src/app/feature/Users/interfaces/interfaces';
import {
  AccountInitialValues,
  AccountSimplifiedPlanValues
} from 'src/app/feature/Accounts/Interfaces/interfaces';

const initialState: OrdersState = {
  orders: {
    docs: [],
    totalDocs: 0,
    limit: 0,
    totalPages: 0,
    page: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: 0,
    nextPage: 0
  },
  order: {
    _id: '',
    code: '',
    description: '',
    total: 0,
    status: '',
    createdAt: '',
    account: AccountInitialValues,
    paymentStatus: '',
    paymentType: '',
    createdById: UserInitialValues,
    foods: [],
    food: [],
    paymentId: '',
    tableId: {
      _id: '',
      name: '',
      isEnable: false,
      account: AccountSimplifiedPlanValues
    },
    tracking: [],
    electronicInvoice: null,
    orderType: ''
  }
};

export default function ordersReducer(
  state = initialState,
  action: TypesActionsOrders
) {
  switch (action.type) {
    case LIST_ORDERS: {
      const orders = action.payload;
      return {
        ...state,
        orders: orders
      };
    }
    case UPDATE_ORDER: {
      const order = action.payload;
      const index = state.orders.docs.findIndex(
        (todo) => todo._id === order._id
      );
      const newArray = [...state.orders.docs]; //making a new array
      newArray[index] = action.payload; //changing value in the new array
      const { docs, ...rest } = state.orders;
      const orders = { docs: newArray, ...rest };
      return {
        ...state, //copying the orignal state
        orders //reassingning todos to new array
      };
    }
    case ADD_ORDER: {
      const order = action.payload;
      return {
        ...state,
        order
      };
    }
    default:
      return state;
  }
}
