import {
  TypesActionsFoods,
  LIST_FOODS,
  ADD_FOOD,
  UPDATE_FOOD
} from '../../actions/foods/FoodsTypes';
import { FoodsState } from '../../models/foods/FoodsState';

const initialState: FoodsState = {
  foods: {
    docs: [],
    totalDocs: 0,
    limit: 0,
    totalPages: 0,
    page: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: 0,
    nextPage: 0
  },
  food: {
    _id: '',
    account: '',
    complements: [],
    createdAt: '',
    createdBy: '',
    deletedAt: null,
    description: '',
    discountPercent: 0,
    foodCategoryId: [],
    image: '',
    name: '',
    price: 0,
    status: ''
  }
};

export default function foodsReducer(
  state = initialState,
  action: TypesActionsFoods
) {
  switch (action.type) {
    case LIST_FOODS: {
      const foods = action.payload;
      return {
        ...state,
        foods: foods
      };
    }
    case UPDATE_FOOD: {
      const food = action.payload;
      const index = state.foods.docs.findIndex((todo) => todo._id === food._id);
      const newArray = [...state.foods.docs]; //making a new array
      newArray[index] = action.payload; //changing value in the new array
      const { docs, ...rest } = state.foods;
      const foods = {
        docs: newArray,
        ...rest
      };
      return {
        ...state, //copying the orignal state
        foods //reassingning todos to new array
      };
    }
    case ADD_FOOD: {
      const food = action.payload;
      return {
        ...state,
        food
      };
    }
    default:
      return state;
  }
}
