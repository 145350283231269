import {
  TypesActionsReports,
  GET_ORDERS_REPORT,
  GET_USERS_REPORT,
  GET_ACCOUNTS_REPORT
} from '../../actions/reports/ReportsTypes';
import { ReportsState } from '../../models/reports/ReportsState';

const initialState: ReportsState = {
  ordersReport: {
    totalTransactions: 0,
    methods: [],
    status: []
  },
  usersReport: {
    total: 0,
    status: [],
    roles: []
  },
  accountsReport: {
    users: {
      total: 0,
      status: [],
    },
    orders: {
      totalTransactions: 0,
      methods: [],
      status: []
    },
    foods: 0
  }
};

export default function reportsReducer(
  state = initialState,
  action: TypesActionsReports
) {
  switch (action.type) {
    case GET_ORDERS_REPORT: {
      const ordersReport = action.payload;
      return {
        ...state,
        ordersReport
      };
    }
    case GET_USERS_REPORT: {
      const usersReport = action.payload;
      return {
        ...state,
        usersReport
      };
    }
    case GET_ACCOUNTS_REPORT: {
      const accountsReport = action.payload;
      return {
        ...state,
        accountsReport
      };
    }
    default: {
      return state;
    }
  }
}
