export enum CategoryTypes {
  DEFAULT = 'default',
  CUSTOM = 'custom'
}

export enum CategoryTypesSpanish {
  DEFAULT = 'Por Defecto',
  CUSTOM = 'Personalizada'
}

export enum UsersRole {
  SUPERADMIN = 'superadmin',
  ADMIN = 'admin',
  OWNER = 'owner',
  MANAGER = 'manager',
  KITCHEN = 'kitchen',
  HOST = 'host',
  CLIENT = 'client'
}

export enum AccountStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export enum AccountStatusEnumSpanish {
  ACTIVE = 'ACTIVO',
  INACTIVE = 'INACTIVO'
}

export enum PaymentOrderStatus {
  PENDING = 'Pendiente',
  COMPLETED = 'Completada'
}

export enum DateSelectorEnum {
  Today = 'TODAY',
  LastSevenDays = 'LASTSEVENDAYS',
  Range = 'RANGE',
  Month = 'MONTH'
}

export enum CategoryStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}

export enum CategoryStatusSpanish {
  ACTIVE = 'activo',
  INACTIVE = 'inactivo'
}

export enum UsersStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}

export enum UsersStatusSpanish {
  ACTIVE = 'activo',
  INACTIVE = 'inactivo'
}

export enum ComplementsStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}

export enum ComplementsStatusSpanish {
  ACTIVE = 'activo',
  INACTIVE = 'inactivo'
}

export enum IsEnableEnum {
  ENABLE = 'ENABLE',
  DISABLE = 'DISABLE'
}

export enum IsEnableEnumSpanish {
  ENABLE = 'HABILITADO',
  DISABLE = 'INHABILITADO'
}

export enum IsEnableTableEnum {
  ENABLE = 'ACTIVE',
  DISABLE = 'INACTIVE'
}

export enum IsEnableTableEnumSpanish {
  ENABLE = 'ACTIVO',
  DISABLE = 'INACTIVO'
}

export enum PaymentTypes {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE'
}

export enum StatusOrder {
  GENERATED = 'Generada',
  INPREPARATION = 'En preparación',
  DELIVERED = 'Entregada',
  COMPLETED = 'Completada',
  CANCELLED = 'Anulada',
  TOVERIFY = 'Por Verificar'
}

export enum RefreshIntervals {
  TENSECONDS = '10000',
  THIRTYSECONDS = '30000',
  SIXTYSECONDS = '60000'
}

export enum CustomFilterTypes {
  SELECT = 'select',
  INPUT_TEXT = 'input_text',
  INPUT_DATE = 'input_DATE'
}

export enum PlanStatusEnum {
  active = 'ACTIVO',
  inactive = 'INACTIVO'
}

export enum InvoiceStatusEnum {
  PAID = 'paid',
  PENDING = 'pending'
}

export enum InvoiceStatusEnumSpanish {
  PAID = 'pagado',
  PENDING = 'pendiente'
}

export enum PlanTypeEnum {
  qr = 'QR',
  pos = 'POS'
}
