import {
  Invoice,
  ResponseInvoiceProps
} from 'src/app/feature/Receipts/model/receipts';

export const LIST_INVOICES = 'LIST_INVOICES';
export const ADD_INVOICE = 'ADD_INVOICE';
export const UPDATE_INVOICE = 'UPDATE_INVOICE';

interface ActionListInvoices {
  type: typeof LIST_INVOICES;
  payload: ResponseInvoiceProps;
}

interface ActionAddInvoice {
  type: typeof ADD_INVOICE;
  payload: Invoice;
}

interface ActionUpdateInvoice {
  type: typeof UPDATE_INVOICE;
  payload: string;
}

export type TypesActionsInvoices =
  | ActionListInvoices
  | ActionAddInvoice
  | ActionUpdateInvoice;
