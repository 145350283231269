import { useRoutes } from 'react-router-dom';
import routesAuth from './routesAuth';
import routesProtected from './routesProtected';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Provider } from 'react-redux';
import store from 'src/app/core/redux/store';
import ThemeProvider from './app/shared/theme/ThemeProvider';
import { CssBaseline } from '@mui/material';

const App = () => {
  const auth = useRoutes(routesAuth);
  const contentProtected = useRoutes(routesProtected);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <Provider store={store}>
          {localStorage.getItem('user') ? contentProtected : auth}
        </Provider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};
export default App;
