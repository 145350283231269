import * as Yup from 'yup';
import {
  Account,
  ResponseAccountsProps,
  AccountSimplifiedPlan
} from '../models/Accounts';
import { AccountStatusEnum } from 'src/app/core/config/enums';

export interface ManagementAccountsProps {
  accounts: ResponseAccountsProps;
  getAccounts: (
    page: number,
    limit: number,
    search: string | null
  ) => Promise<void>;
  deleteAccount: (id: string) => Promise<void>;
}

export interface ManagementAccountsCreateProps {
  addAccount: (
    form: Object,
    callback: Function,
    setValidate: (value: boolean) => void
  ) => Promise<void>;
}

export interface ManagementAccountsEditProps {
  editAccount: (
    form: Object,
    callback: Function,
    setValidate: (value: boolean) => void
  ) => Promise<void>;
  getAccount: (id: string, callback: Function) => void;
  account: Account;
}

export interface AccountFormValues {
  name: string;
  documentId: string;
  country: string;
  state: string;
  city: string;
  address: string;
  zip: string;
  phone: string;
  email: string;
  representative: string;
  description: string;
  planQR: string;
  planNameQR: string;
  planDescriptionQR: string;
  planPriceQR: number;
  setupFeeQR: number;
  planPOS: string;
  planNamePOS: string;
  planDescriptionPOS: string;
  planPricePOS: number;
  setupFeePOS: number;
  isEnable: boolean;
  lat: string;
  lon: string;
  status: string;
  image?: string;
  banner?: string;
  fileImage?: any;
  fileBanner?: any;
  id?: string;
  _id?: string;
  feeSetupNumberParts?: number;
  feeSetupTotalAmount?: number;
  feeSetupObservation?: string;
  feeSetupCheck?: boolean;
}

export interface ManagementAccountsDetailProps {
  getAccount: (id: string, callback: Function) => void;
  account: Account;
}

export interface GeneralInfoProps {
  account: Account;
}

export const initialValues: AccountFormValues = {
  name: '',
  documentId: '',
  country: '',
  state: '',
  city: '',
  address: '',
  zip: '',
  phone: '',
  email: '',
  representative: '',
  description: '',
  planQR: '',
  planNameQR: '',
  planDescriptionQR: '',
  planPriceQR: null,
  planPOS: '',
  planNamePOS: '',
  planDescriptionPOS: '',
  planPricePOS: null,
  isEnable: true,
  lat: '',
  lon: '',
  status: AccountStatusEnum.ACTIVE,
  feeSetupNumberParts: 1,
  setupFeeQR: null,
  setupFeePOS: null,
  feeSetupTotalAmount: null,
  feeSetupObservation: '',
  feeSetupCheck: false
};

export const validationSchema: Yup.SchemaOf<AccountFormValues> = Yup.object({
  name: Yup.string().required('Este campo es requerido'),
  documentId: Yup.string().required('Este campo es requerido'),
  country: Yup.string().required('Este campo es requerido'),
  state: Yup.string().required('Este campo es requerido'),
  city: Yup.string().required('Este campo es requerido'),
  address: Yup.string().required('Este campo es requerido'),
  zip: Yup.string().required('Este campo es requerido'),
  phone: Yup.string().required('Este campo es requerido'),
  email: Yup.string()
    .required('Este campo es requerido')
    .email('Debe introducir un email válido'),
  representative: Yup.string().required('Este campo es requerido'),
  description: Yup.string().required('Este campo es requerido'),
  planQR: Yup.string(),
  planNameQR: Yup.string().nullable(),
  planDescriptionQR: Yup.string().nullable(),
  planPriceQR: Yup.number().nullable(),
  planPOS: Yup.string().when('planQR', {
    is: (plan: string) => !plan,
    then: Yup.string().required('Debe seleccionar algun plan')
  }),
  planNamePOS: Yup.string().nullable(),
  planDescriptionPOS: Yup.string().nullable(),
  planPricePOS: Yup.number().nullable(),
  setupFeeQR: Yup.number().nullable(),
  setupFeePOS: Yup.number().nullable(),
  setupFee: Yup.number().nullable(),
  isEnable: Yup.boolean().required('Este campo es requerido'),
  lat: Yup.string().required('Seleccione una ubicación en el mapa'),
  lon: Yup.string().required('Seleccione una ubicación en el mapa'),
  status: Yup.string().required('Este campo es requerido'),
  image: Yup.string(),
  banner: Yup.string(),
  fileImage: Yup.object().nullable(),
  fileBanner: Yup.object().nullable(),
  id: Yup.string(),
  _id: Yup.string(),
  feeSetupNumberParts: Yup.number().when('_id', (_id: string) => {
    if (_id) {
      return Yup.number();
    } else {
      return Yup.number()
        .typeError('Debe ser un número')
        .required('Esta campo es requerido');
    }
  }),
  feeSetupCheck: Yup.boolean(),
  feeSetupTotalAmount: Yup.number().when(
    'feeSetupCheck',
    (feeSetupCheck: boolean) => {
      if (feeSetupCheck) {
        return Yup.number()
          .positive('Debe ser una cantidad positiva')
          .typeError('Debe ser un número')
          .required('Este campo es requerido');
      } else {
        return Yup.number().nullable();
      }
    }
  ),
  feeSetupObservation: Yup.string().when(
    'feeSetupCheck',
    (feeSetupCheck: boolean) => {
      if (feeSetupCheck) {
        return Yup.string().required('Este campo es requerido');
      } else {
        return Yup.string();
      }
    }
  )
});

export const AccountInitialValues: Account = {
  _id: '',
  id: '',
  name: '',
  documentId: '',
  country: '',
  state: '',
  city: '',
  address: '',
  zip: '',
  phone: '',
  email: '',
  representative: '',
  description: '',
  plan: {
    _id: '',
    name: '',
    description: '',
    active: false,
    price: 0,
    setupFee: 0,
    rules: {},
    planType: ''
  },
  planPos: {
    _id: '',
    name: '',
    description: '',
    active: false,
    price: 0,
    setupFee: 0,
    rules: {},
    planType: ''
  },
  isEnable: false,
  lat: '',
  lon: '',
  status: '',
  image: '',
  banner: '',
  createdAt: '',
  deletedAt: '',
  __v: 0,
  feeServicesQuota: [],
  feeSetupTotalAmmount: 0,
  feeSetupObservation: ''
};

export const AccountSimplifiedPlanValues: AccountSimplifiedPlan = {
  _id: '',
  name: '',
  documentId: '',
  country: '',
  state: '',
  city: '',
  address: '',
  zip: '',
  phone: '',
  email: '',
  representative: '',
  description: '',
  plan: '',
  planPos: '',
  isEnable: false,
  lat: '',
  lon: '',
  status: '',
  image: '',
  banner: '',
  createdAt: '',
  feeServicesQuota: []
};
