import {
  Account,
  ResponseAccountsProps
} from 'src/app/feature/Accounts/models/Accounts';

export const LIST_ACCOUNTS = 'LIST_ACCOUNTS';
export const ADD_ACCOUNT = 'ADD_ACCOUNT';
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';

interface ActionListAccounts {
  type: typeof LIST_ACCOUNTS;
  payload: ResponseAccountsProps;
}

interface ActionAddAccounts {
  type: typeof ADD_ACCOUNT;
  payload: Account;
}

interface ActionUpdateAccount {
  type: typeof UPDATE_ACCOUNT;
  payload: Account;
}

export type TypesActionsAccounts =
  | ActionListAccounts
  | ActionAddAccounts
  | ActionUpdateAccount;
