import { ReactNode } from 'react';
import TableChartTwoToneIcon from '@mui/icons-material/TableChartTwoTone';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import ListTwoToneIcon from '@mui/icons-material/ListTwoTone';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import AssessmentIcon from '@mui/icons-material/Assessment';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PaymentsIcon from '@mui/icons-material/Payments';
import CardMembershipIcon from '@mui/icons-material/CardMembership';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: '',
    items: [
      {
        name: 'Dashboard',
        link: '/dashboard',
        icon: DashboardTwoToneIcon
      },
      {
        name: 'Restaurantes',
        link: '/accounts',
        icon: RestaurantIcon
      },
      {
        name: 'Categorías de Productos',
        icon: ListTwoToneIcon,
        link: '/foodCategories'
      },
      {
        name: 'Usuarios',
        icon: PeopleAltIcon,
        link: '/users'
      },
      {
        name: 'Transacciones',
        icon: TableChartTwoToneIcon,
        link: '/management/transactions'
      },
      {
        name: 'Reportes',
        link: '/reports',
        icon: AssessmentIcon
      },
      {
        name: 'Perfil de Usuario',
        icon: AccountCircleTwoToneIcon,
        link: '/profile'
      },
      {
        name: 'Planes',
        icon: PaymentsIcon,
        link: '/plans'
      },
      {
        name: 'Membresías',
        icon: CardMembershipIcon,
        link: '/receipts'
      }
    ]
  }
];

export default menuItems;
