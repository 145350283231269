import {
  Order,
  ResponseOrdersProps
} from 'src/app/feature/Orders/models/Orders';

export const LIST_ORDERS = 'LIST_ORDERS';
export const ADD_ORDER = 'ADD_ORDER';
export const UPDATE_ORDER = 'UPDATE_ORDER';

interface ActionListOrders {
  type: typeof LIST_ORDERS;
  payload: ResponseOrdersProps;
}

interface ActionAddOrders {
  type: typeof ADD_ORDER;
  payload: Order;
}

interface ActionUpdateOrders {
  type: typeof UPDATE_ORDER;
  payload: Order;
}


export type TypesActionsOrders =
  | ActionListOrders
  | ActionAddOrders
  | ActionUpdateOrders;
