import * as Yup from 'yup';
import { FormikProps } from 'formik';
import { User, ResponseUsersProps } from '../models/User';
import { UsersRole } from 'src/app/core/config/enums';

export interface UsersFormAddress {
  country: string;
  state: string;
  city: string;
  municipality: string;
  postalCode: string;
  address: string;
  addressType: string;
}
export interface UsersFormCars {
  plateNumber: string;
  brand: string;
  model: string;
  color: string;
  year: string;
}
export interface UsersFormHealthyConditions {
  allergies?: string;
  diseases?: string;
  disabilities?: string;
}

export interface UsersFormValues {
  _id?: string;
  documentId: string;
  country: string;
  state: string;
  city: string;
  address: string;
  zipCode: string;
  dob: string;
  password?: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  isEnable: boolean;
  account?: Array<string>;
  role: string;
  image?: string;
  status: string;
  file?: any;
  canPrepare?: boolean;
  auxRole?: string;
}

export interface ManagementUsersProps {
  users: ResponseUsersProps;
  getUsers: (
    page: number,
    limit: number,
    search: string | null
  ) => Promise<void>;
  deleteUser: (userId: object) => Promise<void>;
}

export interface ManagementUsersCreateProps {
  createUser: (
    form: object,
    callback: Function,
    setValidate: (value: boolean) => void
  ) => Promise<void>;
}

export interface ManagementUsersUpdateProps {
  user?: User;
  updateUser: (
    form: object,
    callback: Function,
    setValidate: (value: boolean) => void
  ) => Promise<void>;
  getUser: (id: string, callback: Function) => void;
  clearUser: Function;
}

export interface ManagementUsersDetailProps {
  user?: User;
  getUser: (id: string, callback: Function) => void;
  clearUser: Function;
}
export interface UserFormProps {
  formikInstance: FormikProps<UsersFormValues>;
}

export const initialValues: UsersFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  role: '',
  status: '',
  documentId: '',
  country: '',
  state: '',
  city: '',
  address: '',
  zipCode: '',
  dob: '',
  password: '',
  isEnable: true,
  account: [],
  canPrepare: false,
  auxRole: ''
};

export const validationSchema: Yup.SchemaOf<UsersFormValues> = Yup.object({
  _id: Yup.string(),
  firstName: Yup.string().required('Este campo es requerido'),
  lastName: Yup.string().required('Este campo es requerido'),
  email: Yup.string()
    .required('Este campo es requerido')
    .email('Debe introducir un email válido'),
  phone: Yup.string().required('Este campo es requerido'),
  role: Yup.string().when('auxRole', (role: string) => {
    if (role === UsersRole.CLIENT) {
      return Yup.string();
    } else {
      return Yup.string().required('Este campo es requerido');
    }
  }),
  status: Yup.string().required('Este campo es requerido'),
  date: Yup.date(),
  documentId: Yup.string().when('role', (role: string) => {
    if (role === UsersRole.CLIENT) {
      return Yup.string();
    } else {
      return Yup.string().required('Este campo es requerido');
    }
  }),
  country: Yup.string().when('role', (role: string) => {
    if (role === UsersRole.CLIENT) {
      return Yup.string();
    } else {
      return Yup.string().required('Este campo es requerido');
    }
  }),
  state: Yup.string().when('role', (role: string) => {
    if (role === UsersRole.CLIENT) {
      return Yup.string();
    } else {
      return Yup.string().required('Este campo es requerido');
    }
  }),
  city: Yup.string().when('role', (role: string) => {
    if (role === UsersRole.CLIENT) {
      return Yup.string();
    } else {
      return Yup.string().required('Este campo es requerido');
    }
  }),
  address: Yup.string().when('role', (role: string) => {
    if (role === UsersRole.CLIENT) {
      return Yup.string();
    } else {
      return Yup.string().required('Este campo es requerido');
    }
  }),
  zipCode: Yup.string().when('role', (role: string) => {
    if (role === UsersRole.CLIENT) {
      return Yup.string();
    } else {
      return Yup.string().required('Este campo es requerido');
    }
  }),
  dob: Yup.string().when('role', (role: string) => {
    if (role === UsersRole.CLIENT) {
      return Yup.string();
    } else {
      return Yup.string().required('Este campo es requerido');
    }
  }),
  password: Yup.string().when('_id', (id: string) => {
    if (!id) {
      return Yup.string()
        .min(8, 'Mínimo 8 caracteres')
        .matches(/[0-9]/, 'Debe contener al menos un número')
        .matches(/[A-Z]/, 'Debe contener al menos una letra mayúscula')
        .matches(/[^\w]/, 'Debe contener al menos un caracter especial')
        .required('Este campo es requerido.');
    } else {
      return Yup.string();
    }
  }),
  isEnable: Yup.bool().when('role', (role: string) => {
    if (role === UsersRole.CLIENT) {
      return Yup.bool();
    } else {
      return Yup.bool().required('Este campo es requerido');
    }
  }),
  account: Yup.array().nullable(),
  image: Yup.string(),
  file: Yup.object().nullable(),
  canPrepare: Yup.boolean().nullable(),
  auxRole: Yup.string()
});

export const UserInitialValues: User = {
  _id: '',
  documentId: '',
  country: '',
  state: '',
  city: '',
  address: '',
  firstName: '',
  lastName: '',
  zipCode: '',
  status: '',
  isEnable: false,
  dob: '',
  account: [],
  email: '',
  phone: '',
  role: '',
  image: '',
  createdBy: '',
  createdAt: '',
  deletedAt: '',
  canPrepare: false,
  __v: 0
};
