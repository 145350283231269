import {
  TypesActionsPlans,
  ADD_PLAN,
  LIST_PLANS,
  UPDATE_PLAN
} from '../../actions/plans/PlansTypes';
import { PlansState } from '../../models/plans/PlansState';

const initialState: PlansState = {
  plan: {
    _id: '',
    name: '',
    description: '',
    active: false,
    price: 0,
    setupFee: 0,
    rules: {},
    planType: ''
  },
  plans: {
    docs: [],
    totalDocs: 0,
    limit: 0,
    totalPages: 0,
    page: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: 0,
    nextPage: 0
  }
};

export default function plansReducer(
  state = initialState,
  action: TypesActionsPlans
): PlansState {
  switch (action.type) {
    case ADD_PLAN: {
      const plan = action.payload;
      return {
        ...state,
        plan
      };
    }
    case LIST_PLANS: {
      const plans = action.payload;
      return {
        ...state,
        plans
      };
    }
    case UPDATE_PLAN: {
      const plan = action.payload;
      const index = state.plans.docs.findIndex((e) => e._id === plan._id);
      const newArray = [...state.plans.docs];
      newArray[index] = plan;
      return {
        ...state,
        plans: {
          ...state.plans,
          docs: newArray
        }
      };
    }
    default:
      return state;
  }
}
