import { combineReducers } from 'redux';
import products from './product/productReducer';
import users from './users/userReducer';
import user from './user/userReducer';
import foodCategories from './foodCategories/foodCategoriesReducer';
import foodComplements from './foodComplements/foodComplementsReducer';
import foods from './foods/foodsReducer';
import accounts from './accounts/accountsReducer';
import tables from './tables/tableReducer';
import orders from './orders/orderReducer';
import reports from './reports/reportsReducer';
import plans from './plans/plansReducer';
import invoices from './invoices/invoiceReducer';

export default combineReducers({
  products,
  user,
  users,
  foodCategories,
  accounts,
  foodComplements,
  foods,
  tables,
  orders,
  reports,
  plans,
  invoices
});
