import {
  TypesActionsFoodComplements,
  LIST_FOOD_COMPLEMENTS,
  ADD_FOOD_COMPLEMENT,
  UPDATE_FOOD_COMPLEMENT
} from '../../actions/foodComplements/FoodComplementsTypes';
import { FoodComplementsState } from '../../models/foodComplements/FoodComplementsState';

const initialState: FoodComplementsState = {
  foodComplements: {
    docs: [],
    totalDocs: 0,
    limit: 0,
    totalPages: 0,
    page: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: 0,
    nextPage: 0
  },
  foodComplement: {
    _id: '',
    name: '',
    description: '',
    status: '',
    price: 0,
    account: ''
  }
};

export default function foodComplementsReducer(
  state = initialState,
  action: TypesActionsFoodComplements
) {
  switch (action.type) {
    case LIST_FOOD_COMPLEMENTS: {
      const foodComplements = action.payload;
      return {
        ...state,
        foodComplements: foodComplements
      };
    }
    case UPDATE_FOOD_COMPLEMENT: {
      const foodComplement = action.payload;
      const index = state.foodComplements.docs.findIndex(
        (todo) => todo._id === foodComplement._id
      );
      const newArray = [...state.foodComplements.docs]; //making a new array
      newArray[index] = action.payload; //changing value in the new array
      const { docs, ...rest } = state.foodComplements;
      const foodComplements = {
        docs: newArray,
        ...rest
      };
      return {
        ...state, //copying the orignal state
        foodComplements
      };
    }
    case ADD_FOOD_COMPLEMENT: {
      const foodComplement = action.payload;
      return {
        ...state,
        foodComplement
      };
    }
    default:
      return state;
  }
}
