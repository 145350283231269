import {
  OrdersReport,
  UsersReport,
  AccountsReport
} from 'src/app/feature/Report/models/report';

export const GET_ORDERS_REPORT = 'GET_ORDERS_REPORT';
export const GET_ACCOUNTS_REPORT = 'GET_ACCOUNTS_REPORT';
export const GET_USERS_REPORT = 'GET_USERS_REPORT';

interface ActionGetOrdersReport {
  type: typeof GET_ORDERS_REPORT;
  payload: OrdersReport;
}

interface ActionGetUsersReport {
  type: typeof GET_USERS_REPORT;
  payload: UsersReport;
}

interface ActionGetAccountsReport {
  type: typeof GET_ACCOUNTS_REPORT;
  payload: AccountsReport;
}

export type TypesActionsReports =
  | ActionGetOrdersReport
  | ActionGetUsersReport
  | ActionGetAccountsReport;
