import {
  TypesActionsInvoices,
  LIST_INVOICES,
  UPDATE_INVOICE
} from '../../actions/invoices/invoiceTypes';
import { InvoicesState } from '../../models/invoices/InvoiceState';
import { InvoiceStatusEnum } from 'src/app/core/config/enums';

const initialState: InvoicesState = {
  invoices: {
    docs: [],
    totalDocs: 0,
    limit: 0,
    totalPages: 0,
    page: 0,
    pagingCounter: 0,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null
  }
};

export default function invoicesReducer(
  state = initialState,
  action: TypesActionsInvoices
) {
  switch (action.type) {
    case LIST_INVOICES: {
      const invoices = action.payload;
      return {
        ...state,
        invoices
      };
    }
    case UPDATE_INVOICE: {
      let index = -1;
      const invoice = state.invoices.docs.find((e, i) => {
        if (e._id === action.payload) {
          index = i;
          return e;
        } else {
          return undefined;
        }
      });
      const newArray = [...state.invoices.docs];
      if (index !== -1 && invoice) {
        invoice.status = InvoiceStatusEnum.PAID;
        newArray[index] = invoice;
      }
      const { docs, ...rest } = state.invoices;
      const invoices = {
        docs: newArray,
        ...rest
      };
      return {
        ...state,
        invoices
      };
    }
    default:
      return state;
  }
}
